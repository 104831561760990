import { API } from "aws-amplify";
import { atom, selector } from "recoil";
import i18n from "../i18n";

const apiName = "steeperApi";

export const firmwareListAtom = atom({
	key: "firmwareListAtom",
	default: null,
});

export const firmwareListSelector = selector({
	key: "firmwareListSelector",
	get: async () => {
		const resp = await API.get(apiName, "/firmware");
		return resp;
	},
	set: ({ set }, newValue) => {
		set(firmwareListAtom, newValue);
	},
});

export const firmwareCurrentActiveSelector = selector({
	key: "firmwareCurrentActiveSelector",
	get: ({ get }) => {
		let currentActiveFirmware = { version: "0.00" };
		const firmwareList = get(firmwareListAtom);
		if (firmwareList) currentActiveFirmware = firmwareList.find((f) => f.active === true);
		if (!currentActiveFirmware) currentActiveFirmware = { version: "0.00" };

		return currentActiveFirmware;
	},
});

export const firmwareCurrentStatusSelector = selector({
	key: "firmwareCurrentStatusSelector",
	get: ({ get }) => {
		let currentTestFirmware = null;
		let currentPublicFirmware = null;
		const firmwareList = get(firmwareListAtom);
		if (firmwareList) {
			currentTestFirmware = firmwareList.find((f) => f.status === 1);
			currentPublicFirmware = firmwareList.find((f) => f.status === 2);
		}

		return { test: currentTestFirmware, public: currentPublicFirmware };
	},
});

export const selectedFirmwareAtom = atom({
	key: "selectedFirmwareAtom",
	default: null,
});

export const selectedFirmwareSelector = selector({
	key: "selectedFirmwareSelector",
	get: async ({ get }) => {
		let firmware = null;
		const selectedFirmware = get(selectedFirmwareAtom);
		if (selectedFirmware) {
			firmware = await API.get(apiName, `/firmware/${selectedFirmware.id}`);
		}
		return firmware;
	},
});

export const firmwareUploadedBySelector = selector({
	key: "firmwareUploadedBySelector",
	get: async ({ get }) => {
		const firmware = get(selectedFirmwareAtom);
		const users = await API.get(apiName, `/user/byAttribute?name=sub&type=%3D&value=${firmware.createdBy}`);
		return users.length
			? users[0]
			: { fullname: `${i18n.t("common.deletedUser")}: ${firmware.createdBy.slice(-4)}` };
	},
});
